import { Link } from "@remix-run/react";

export function Footer() {
  return (
    <footer className="flex mx-auto flex-col md:flex-row w-full gap-8 justify-center items-center">
      <ul className="flex flex-col gap-3 text-center md:text-left md:mr-auto md:mb-auto">
        <h4 className="text-gray-300 text-lg font-semibold">Jobs by city</h4>
        <li className="text-gray-400">
          <Link to="/city/london">Software Engineering Jobs in London</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/dublin">Software Engineering Jobs in Dublin</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/amsterdam">
            Software Engineering Jobs in Amsterdam
          </Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/berlin">Software Engineering Jobs in Berlin</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/bucharest">
            Software Engineering Jobs in Bucharest
          </Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/cambridge">
            Software Engineering Jobs in Cambridge
          </Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/madrid">Software Engineering Jobs in Madrid</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/zurich">Software Engineering Jobs in Zurich</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/gdansk">Software Engineering Jobs in Gdańsk</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/city/prague">Software Engineering Jobs in Prague</Link>
        </li>
      </ul>
      <ul className="flex flex-col gap-3 text-center md:text-left md:mb-auto">
        <h4 className="text-gray-300 text-lg font-semibold">Jobs by country</h4>

        <li className="text-gray-400">
          <Link to="/country/ie">Software Engineering Jobs in Ireland</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/pl">Software Engineering Jobs in Poland</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/gb">Software Engineering Jobs in the UK</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/de">Software Engineering Jobs in Germany</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/es">Software Engineering Jobs in Spain</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/fr">Software Engineering Jobs in France</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/nl">
            Software Engineering Jobs in the Netherlands
          </Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/pt">Software Engineering Jobs in Portugal</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/ro">Software Engineering Jobs in Romania</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/country/ch">Software Engineering Jobs in Switzerland</Link>
        </li>
        <li className="text-gray-400"></li>
      </ul>

      <ul className="flex flex-col gap-3 text-center md:text-right md:ml-auto md:mb-auto">
        <li className="text-gray-300 text-lg font-semibold">Company</li>
        <li className="text-gray-400">
          <Link to="/blog">Blog</Link>
        </li>
        <li className="text-gray-400">
          <Link to="/privacy-policy">Privacy Policy and Terms of Service</Link>
        </li>
      </ul>
    </footer>
  );
}
